import * as React from "react";
import App from "@launchos/modules/app";

import PageBuilder from "@launchos/modules/editor/Editor";

import "@launchos/10mf/src/index.css";
import "@launchos/10mf/src/style.css";
import WebFontLoader from "webfontloader";
// import { AppConfig } from "@launchos/modules/app";
import settings from "@launchos/10mf/src/config";
import { settings01 } from "@launchos/plugins/misc/v2/blocks/weblayouts/Benefits";

import { addThisAfterThat } from "@launchos/modules/v2/Editor/actions";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BODY } from "@launchos/modules/v2/Editor/types";

import EditorCanvas from "@launchos/modules/editor/Builder/PureCanvas";
import { EditorProps } from "@launchos/modules/editor/Builder/types";
import { DndProvider } from "react-dnd";

import Backend from "react-dnd-html5-backend";


export const sampleContentData = [
  {
    parent: "u5j1r64m5rf",
    id: "bj51e45wmym",
    properties: {
      // marginTop: 50,
      marginTop: 0,
      color: "#95a5a6",
      textAlign: "center",
      fontFamily: "Advent Pro",
      fontSize: 36,
      padding: 5,
      display: "inline-block",
    },
    type: "Paragraph",
    html: "<p>Replace this content with your own</p>",
  },
  {
    parent: "u5j1r64m5rf",
    id: "hm3iurc13pd",
    properties: {
      marginTop: 2,
      color: "#95a5a6",
      textAlign: "center",
      fontFamily: "Advent Pro",
      fontSize: 20,
      padding: 5,
      mobile: {
        desktop: {
          marginTop: 2,
          color: "#95a5a6",
          textAlign: "center",
          fontFamily: "Advent Pro",
          fontSize: 20,
        },
      },
      display: "inline-block",
    },
    type: "Paragraph",
    html: "<p>(Just select me and hit your keyboard's [DELETE] key)</p>",
  },
  {
    parent: 2540,
    id: "u5j1r64m5rf",
    properties: {
      width: "100%",
      mobile: {
        desktop: {
          padding: 100,
        },
      },
      padding: 100,
    },
    type: "Container",
    canHaveChildren: true,
  },
  {
    parent: false,
    state: "normal",
    id: BODY,
    properties: {
      backgroundColor: "#ffffff",
      backgroundImage: "none",
      height: "100%",
      backgroundAttachment: "scroll",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "repeat-y",
      mobile: {
        desktop: {
          backgroundColor: "#ffffff",
          backgroundImage: "none",
          backgroundAttachment: "scroll",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "repeat-y",
        },
      },
    },
    type: ComponentTypes.BODY,
    canHaveChildren: true,
  },
];

// const xsampleContentData = settings.pages.blankPage;

export const mobileStateData = {
  desktop: {
    type: "desktop",
    dimensions: {
      maxWidth: 1000,
      minHeight: "70vh",
      margin: "80px auto",
      overflow: "hidden",
    },
  },
  fullscreen: {
    type: "fullscreen",
    dimensions: {
      width: "100%",
      height: "100vh",
      marginTop: 57,
    },
  },
};

export const buildContentData = (children) => {
  const content = addThisAfterThat(
    sampleContentData.filter((itm) => itm.type === ComponentTypes.BODY),
    {
      type: ComponentTypes.CONTAINER,
      canHaveChildren: true,
      properties: { display: "block" },
      justAdded: true,
      children,
    },
    BODY
  );
  content[content.findIndex((itm) => itm.justAdded)]["parent"] = BODY;
  return content;
};

export const EditorWithCustomData = ({
  content,
  defaultMobileState = mobileStateData.fullscreen,
  log = () => null,
  onSetActiveObject = () => null,
  onScheduleUpdate = () => null,
  onChangeState = () => null,
  onAddThisAfterThat = () => null,
  onRemoveItem = () => null,
  onDuplicateItem = () => null,
  onMoveThisByThat = () => null,
  onUpdateComponentSettings = () => null,
  onUpdateComponentStyle = () => null,
  onUpdateContent = () => null,
  onListComponents = (content = [], filterOut = [], filterOnly = []) => null,
  onDoUndoRedo = () => null,
  onSetMobileState = () => null,
  onGetMobileState = () => null,
  onActivePropertyWindows = () => null,
  onGetActivePropertyWindows = () => null,
  onHandleUpdatePageContent = () => null,
  onSetCanDrag = () => null,
  onGetCanDrag = () => null,
}) => {
  WebFontLoader.load({
    google: {
      families: [
        "Roboto:300,400,500,700",
        "Material Icons",
        "Poppins:300,400,500,700",
      ],
    },
  });
  return (
    <App {...settings}>
      <DndProvider backend={Backend}>
        <div style={{ minHeight: "100vh" }}>
          <PageBuilder
            history={{
              location: {
                pathname: window.location.pathname,
              },
            }}
            match={{
              path: "",
              params: {
                campaignId: "cjyuopfrx00ga0792rxk0yha9",
                nodeId: "cjyuopfxq00gh07924rep6q96",
              },
            }}
            getPageQuery={{
              page: {
                type: false,
                name: "",
                id: "",
                content,
              },
              refetch: () => {
                return {
                  data: { page: { content } },
                };
              },
            }}
            updatePageContent={({ variables }) => {
              log("update", variables);
              return {
                then: (response) => response,
              };
            }}
            show="builder"
            defaultMobileState={defaultMobileState}
            log={log}
            onSetActiveObject={onSetActiveObject}
            onScheduleUpdate={onScheduleUpdate}
            onChangeState={onChangeState}
            onAddThisAfterThat={onAddThisAfterThat}
            onRemoveItem={onRemoveItem}
            onDuplicateItem={onDuplicateItem}
            onMoveThisByThat={onMoveThisByThat}
            onUpdateComponentSettings={onUpdateComponentSettings}
            onUpdateComponentStyle={onUpdateComponentStyle}
            onUpdateContent={onUpdateContent}
            onListComponents={onListComponents}
            onDoUndoRedo={onDoUndoRedo}
            onSetMobileState={onSetMobileState}
            onGetMobileState={onGetMobileState}
            onActivePropertyWindows={onActivePropertyWindows}
            onGetActivePropertyWindows={onGetActivePropertyWindows}
            onHandleUpdatePageContent={onHandleUpdatePageContent}
            onSetCanDrag={onSetCanDrag}
            onGetCanDrag={onGetCanDrag}
          />
        </div>
      </DndProvider>
    </App>
  );
};

export const DefaultEditor = () => {
  return <EditorWithCustomData content={sampleContentData} />;
};

export const APureCanvas = () => {
  const [pageContent, setPageContent] = React.useState(
    buildContentData([settings01.default])
  );

  return (
    <EditorCanvas
      content={pageContent}
      onChange={({ content = [] }) => {
        if (content.length) setPageContent(content);
      }}
      onSave={({ content = [] }) => {
        if (content.length)
          console.log(
            "We should save the following content to the database",
            content
          );
      }}
      onItemSelect={(id, settings) =>
        console.log(`You seleceted item id ${id}`, settings)
      }
      render={(settings) => {
        if (settings.type === "Picture")
          return (
            <div
              style={{
                padding: 50,
                backgroundColor: "red",
                color: "white",
                fontSize: 18,
              }}
            >
              This is a picture
            </div>
          );
      }}
    >
      {({ duplicateItem }) => (
        <div>
          <button
            onClick={() => {
              console.log(pageContent);

              setPageContent([
                ...pageContent.slice(0, 15),
                { ...pageContent[15], caption1: "This is a test!" },
                ...pageContent.slice(16),
              ]);
            }}
          >
            Testing
          </button>
          <button onClick={() => duplicateItem(pageContent[15].id)}>
            Duplicate
          </button>
        </div>
      )}
    </EditorCanvas>
  );
};

export default DefaultEditor;
