import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { DividerTypes } from "../../../../../../webcomponents/v2/Divider/types";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.TWENTYTWO,
  layout: BenefitLayouts.TWENTYTWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit22.png",
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Featured Benefit",
      },
      {
        type: ComponentTypes.DIVIDER,
        canHaveChildren: false,
        dividerType: DividerTypes.HORIZONTAL,
        color: theme.backgroundColor,
        size: 3,
        properties: {
          marginTop: 30,
          width: 100,
          display: "inline-block",
          textAlign: "center",
        },
      },
      {
        type: ComponentTypes.LIST,
        data: [
          {
            caption: lorem.generateSentences(2),
            style: style.LISTITEM.style,
            iconStyle: style.LISTITEM.iconStyle,
            contentStyle: style.LISTITEM.contentStyle,
            icon: IconTypes.CheckCircleOutline,
          },
          {
            caption: lorem.generateSentences(2),
            style: style.LISTITEM.style,
            iconStyle: style.LISTITEM.iconStyle,
            contentStyle: style.LISTITEM.contentStyle,
            icon: IconTypes.CheckCircleOutline,
          },
          {
            caption: lorem.generateSentences(2),
            style: style.LISTITEM.style,
            iconStyle: style.LISTITEM.iconStyle,
            contentStyle: style.LISTITEM.contentStyle,
            icon: IconTypes.CheckCircleOutline,
          },
          {
            caption: lorem.generateSentences(2),
            style: style.LISTITEM.style,
            iconStyle: style.LISTITEM.iconStyle,
            contentStyle: style.LISTITEM.contentStyle,
            icon: IconTypes.CheckCircleOutline,
          },
          {
            caption: lorem.generateSentences(2),
            style: style.LISTITEM.style,
            iconStyle: style.LISTITEM.iconStyle,
            contentStyle: style.LISTITEM.contentStyle,
            icon: IconTypes.CheckCircleOutline,
          },
        ],
      },
    ],
  },
};

export default settings;
