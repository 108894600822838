import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",
    paddingTop: 50,
    paddingBottom: 0,
    paddingLeft: 50,
    paddingRight: 50,
    mobile: {
      desktop: { padding: 50 },
      smartphone: { padding: 10 },
    },
  },
  HEADLINE: {
    textAlign: "left",
    fontSize: "28pt",
    fontFamily: theme.fontFamily,
    // fontWeight: "bold" as "bold",
    paddingBottom: 30,
    // color: theme.backgroundColor
  },
  ITEM_HEADLINE: {
    textAlign: "left",
    fontSize: "16pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    marginTop: 60,
  },
  TEXT: {
    textAlign: "left",
    fontFamily: theme.fontFamily,
    marginTop: 20,
    fontSize: "13pt",
    lineHeight: "18pt",
  },
  // TEXT: {
  //   textAlign: "left",
  //   fontFamily: theme.fontFamily,
  //   marginTop: 40,
  //   // color: theme.backgroundColor,
  //   fontSize: "8pt"
  // },
  VIDEO: {
    width: "100%",
    margin: "0 auto",
  },
  BUTTON: {
    style: {
      display: "inline-block",
      padding: 15,
      border: "none",
      backgroundColor: theme.backgroundColor,
      marginTop: 50,
      minWidth: 125,
      borderRadius: 3,
      cursor: "pointer",
    },
    caption1: {
      color: theme.foregroundColor,
      fontWeight: "bold" as "bold",
      fontSize: "10pt",
    },
  },
  FORM_FIELD: {
    style: { width: "100%" },
    inputStyle: {
      width: "90%",
      padding: 15,
      backgroundColor: "transparent",
      border: `1px solid ${theme.backgroundColor}`,
      borderRadius: 5,
      color: theme.backgroundColor,
    },
  },
};
