import React from "react";
import { get, uniq } from "lodash";

import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import {
  RichEditor,
  PositionPadding,
  Alignment,
  PropertiesWindow,
} from "@launchos/modules/editor/Builder/Properties";

import {
  generateInlineStyle,
  generateGoogleFontList,
} from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";

import { checkForSmartCopyIntegration } from '@launchos/plugins/misc/v2/smartcopy/client/scripts'

import style from "./style";

const HeadlineContentEdit = (props) => {
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);

  const {
    settings,
    updateComponentSettings,
    pageContent,
    setIsEditing,
    setCanDrag,
    changeState,
  } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["lineheight"],
    ["quicklink"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    usesSmartCopy ? ["smartcopy"] : [],
    ["bold", "italic", "underline", "strikethrough"],
    ["fontformats", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link", "selectall"],
  ];

  const padding = get(settings, "properties.padding", 0);
  // const display = get(settings, 'properties.display', "inline-block");

  // are we in the middle of a drag?  if so, make the editor read only
  const isDragging = pageContent.filter((content) => content.type === "Cursor").length;
  // const isDragging = Boolean(props.isDragInProgress);

  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleOnLoad = async () => {
    const usesIt = await checkForSmartCopyIntegration();
    setUsesSmartCopy(usesIt)
  }

  React.useEffect(() => {
    handleOnLoad()
  }, [])

  return (
    <EditorObject
      {...props}
      // style={{ display }}
      PropertiesView={Properties}
      dragHandle={dragHandleIsShowing}
      noOffset={settings.html.length < 20}
    >
      <p style={{ padding }}> {/* <h1> produces some bad padding */}
        <span
          id={`ed_${settings.id}`}
          style={{
            ...style.content,
            ...settings.properties,
            marginTop: 0,
            padding: 0,
            display: "inline-block",
          }}
        >
          <RichEditor
            content={settings.html}
            style={generateInlineStyle(settings)}
            buttonList={buttonList}
            menuList={menuList}
            googleFontList={googleFontList}
            onChange={(html, fontsList) => {
              try {
                const currentFontList = get(
                  settings,
                  "fontsUsed",
                  []
                ).map((arr) => get(arr, "fontFamily"));
                const newFontList = uniq([...currentFontList, ...fontsList]);
                const fontsUsed = newFontList.map((font) => ({
                  fontFamily: font,
                }));
                updateComponentSettings(settings.id, {
                  ...settings,
                  html,
                  fontsUsed,
                });
              } catch (e) { }
            }}
            onInstantChange={() => setDragHandleIsShowing(false)}
            onClick={() => {
              setDragHandleIsShowing(true);
            }}
            onMouseEnter={() => {
              setDragHandleIsShowing(true);
            }}
            onFocus={(e) => {
              try {
                // console.log("Testing!");
                setIsEditing(true);
                setCanDrag(false);
                changeState(settings.id, "active");
              } catch (e) { }
            }}
            onBlur={(e) => {
              try {
                setDragHandleIsShowing(true);
                setTimeout(() => {
                  setIsEditing(false);
                  setCanDrag(true);
                }, 200);
              } catch (e) { }
            }}
          // disabled={props.show === "preview" || isDragging}
          // disabled={props.show === "preview"}
          // disabled={isDragging}
          // disabled
          // placeholder="Enter Your Headline Here"
          />
        </span>
      </p>
    </EditorObject>
  );
};

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <HeadlineContentEdit {...props} />
    </div>
  );
};

const BasicPropertiesTab = (props) => (
  <div style={{ width: "100%" }}>
    {/* <Typography {...props} showLineHeight isExpanded /> */}
    <PositionPadding {...props} isExpanded />
    <Alignment {...props} verticalAlign={false} textAlign />
  </div>
);

const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Headline", component: BasicPropertiesTab }]}
  />
);
