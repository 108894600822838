import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { DividerTypes } from "../../../../../../webcomponents/v2/Divider/types";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.TWENTYTHREE,
  layout: BenefitLayouts.TWENTYTHREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit23.png",
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          fontSize: "36pt",
          textAlign: "center",
        },
        html: "Features",
      },
      {
        type: ComponentTypes.DIVIDER,
        canHaveChildren: false,
        dividerType: DividerTypes.HORIZONTAL,
        color: theme.backgroundColor,
        size: 3,
        properties: {
          marginTop: 30,
          width: 100,
          display: "inline-block",
          textAlign: "center",
        },
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 40,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                style: {
                  height: "100%",
                  backgroundColor: theme.backgroundColor,
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((9 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum harum inventore repellendus maiores atque praesentium fugit? Accusamus aperiam voluptatibus voluptates aliquam perferendis, est accusantium aut praesentium. Quis enim deleniti quasi?`,
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((9 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum harum inventore repellendus maiores atque praesentium fugit? Accusamus aperiam voluptatibus voluptates aliquam perferendis, est accusantium aut praesentium. Quis enim deleniti quasi?`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                style: {
                  height: "100%",
                  backgroundColor: theme.backgroundColor,
                },
              }),
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
