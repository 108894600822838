import theme from "../../theme";

export const style = {
  SECTION: {
    background: theme.foregroundColor,
  },
  SECTION_INNER: {
    margin: "0 auto",

    mobile: {
      desktop: {
        padding: 50,
      },
      smartphone: {
        padding: 10,
      },
    },
  },
  HEADLINE: {
    textAlign: "center",
    fontSize: "32pt",
    fontFamily: theme.fontFamily,
    fontWeight: "bold" as "bold",
    paddingBottom: 10,
  },
  TEXT: {
    textAlign: "center",
    fontFamily: theme.fontFamily,
    fontSize: "13pt",
    lineHeight: "18pt",
    padding: "20px 0",
  },
  LISTITEM: {
    style: {
      // color: theme.foregroundColor,
      fontFamily: theme.fontFamily,
      padding: "10px 0",
    },
    iconStyle: {
      borderRadius: "100%",
      // backgroundColor: theme.foregroundColor,
      padding: 10,
      color: theme.backgroundColor,
    },
    contentStyle: {
      padding: 10,
    },
  },
};
