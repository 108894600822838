import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { ComponentTypes } from "@launchos/plugins/webcomponents/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@launchos/plugins/webcomponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.SEVENTEEN,
  layout: BenefitLayouts.SEVENTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit17.png",
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 50,
                  mobile: {
                    desktop: { padding: 50 },
                    smartphone: { padding: 10 },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: get(content, "sub_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          backgroundColor: theme.backgroundColor,
                          padding: 20,
                          textAlign: "center",
                          borderRadius: 5,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.Delete,
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              fontWeight: "bold" as "bold",
                              textAlign: "center",
                              padding: 15,
                            },
                            html: "Proven Strategy",
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              textAlign: "center",
                            },
                            html: "It's true. Getting results",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          backgroundColor: theme.backgroundColor,
                          padding: 20,
                          textAlign: "center",
                          borderRadius: 5,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.Delete,
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              fontWeight: "bold" as "bold",
                              textAlign: "center",
                              padding: 15,
                            },
                            html: "Proven Strategy",
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              textAlign: "center",
                            },
                            html: "It's true. Getting results",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          backgroundColor: theme.backgroundColor,
                          padding: 20,
                          textAlign: "center",
                          borderRadius: 5,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.Delete,
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              fontWeight: "bold" as "bold",
                              textAlign: "center",
                              padding: 15,
                            },
                            html: "Proven Strategy",
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              textAlign: "center",
                            },
                            html: "It's true. Getting results",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((6 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          backgroundColor: theme.backgroundColor,
                          padding: 20,
                          textAlign: "center",
                          borderRadius: 5,
                        },
                        children: [
                          {
                            type: ComponentTypes.ICON,
                            canHaveChildren: false,
                            properties: style.ICON,
                            iconType: IconTypes.Delete,
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              fontWeight: "bold" as "bold",
                              textAlign: "center",
                              padding: 15,
                            },
                            html: "Proven Strategy",
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              textAlign: "center",
                            },
                            html: "It's true. Getting results",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
