import { has } from "lodash";
import React, { Component, Fragment } from "react";
import { Redirect, Route } from "react-router-dom";

import App from "@launchos/modules/app";
import {
  Login,
  PasswordReset,
  PasswordUpdate,
} from "@launchos/modules/authentication";
import CampaignScene from "@launchos/modules/campaign";
import Catalog from "@launchos/modules/catalog";
import { campaigns as DashboardCampaign } from "@launchos/modules/dashboard";
import PageEditor from "@launchos/modules/editor";
import SettingsScene from "@launchos/modules/settings";
import { triggerHook } from "@launchos/plugins/client";

import settings from "./config";
import Test from "./xTest";
// import Test from "./Test";

import { ListItemsHooks, HookTriggers } from "@launchos/plugins/types";
import { APureCanvas } from "./xTest/Editor";
// import { APureCanvas } from "@launchos/modules/editor/Builder/Builder.stories";

export const AuthRoute = (props) => {
  if (!localStorage.userId)
    return <Route {...props} render={() => <Redirect to="/login" />} />;

  return <Route {...props} />;
};

const IFrameRedirect = ({ match, location }) => {
  // const [destination, setDestination] = React.useState(false);

  const { token } = match.params;
  // const history = useHistory();

  const doFetch = async () => {
    const options = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
    };

    const host = process.env.REACT_APP_API_ENDPOINT;

    const response = await fetch(
      // `http://${host}:8091/v1/sessions/${token}`,
      // `https://api.tenminutefunnels.com/v1/sessions/${token}`,
      `${host}/sessions/${token}`,
      options
    );

    const user = await response.json();

    if (has(user, "error")) {
      alert(user.error);
      window.location.href = "/login";
    } else {
      localStorage.setItem("userId", user.id);
      sessionStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("email", user.email);

      // redirect to regular link now that we're logged in
      const newUrl = location.pathname.replace(`/r/${token}/`, "/");
      // alert(newUrl)
      window.location.href = newUrl;
      // history.push(newUrl, { from: "iframe" });
      // history.push(newUrl, { state: { from: "iframe" } });
    }
  };

  React.useEffect(doFetch, []);

  return <div>Loading...</div>;
};

class AutoLogin extends Component {
  constructor(props) {
    super(props);
    localStorage.userId = props.match.params.userId;
  }
  render() {
    return <Redirect to="/dashboard/campaigns" {...this.props} />;
  }
}

const LoginRoutes = (props) => (
  <Fragment>
    <Route exact path="/" render={() => <Redirect to="/login" />} />

    <Route
      path="/login"
      render={(urlData) => <Login {...urlData} {...props} />}
    />
    <Route
      path="/reset"
      exact
      render={(urlData) => <PasswordReset {...urlData} {...props} />}
    />
    <Route
      exact
      path="/reset/:token"
      render={(urlData) => <PasswordUpdate {...urlData} {...props} />}
    />
    <Route
      path="/doUserLogin/:userId/:token"
      render={(urlData) => <AutoLogin {...urlData} {...props} />}
    />
  </Fragment>
);

const DashboardRoutes = (props) => (
  <AuthRoute
    exact
    path="/dashboard"
    render={(urlData) => (
      <Redirect to="/dashboard/campaigns" {...urlData} {...props} />
    )}
  />
);

const SettingsRoutes = (props) => (
  <Fragment>
    <AuthRoute
      exact
      path="/settings"
      render={() => <Redirect to="/settings/profile" />}
    />
    <AuthRoute
      path="/settings/profile"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="profile" />
      )}
    />
    <AuthRoute
      exact
      path="/settings/domains"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="domains" />
      )}
    />
    <AuthRoute
      exact
      path="/settings/domains/:step"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="domains" />
      )}
    />
    <AuthRoute
      exact
      path="/settings/domains/:domainId/delete"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="domains" action="delete" />
      )}
    />
    <AuthRoute
      exact
      path="/settings/domains/:domainId/ssl"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="domains" action="ssl" />
      )}
    />
    <AuthRoute
      path="/settings/integrations"
      render={(urlData) => (
        <SettingsScene {...urlData} {...props} tab="integrations" />
      )}
    />
  </Fragment>
);

const CampaignRoutes = (props) => (
  <Fragment>
    <AuthRoute
      exact
      path="/dashboard/campaigns"
      render={(urlData) => <DashboardCampaign {...urlData} {...props} />}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/builder"
      render={(urlData) => (
        <CampaignScene {...urlData} {...props} show="editor" tab="builder" />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/share"
      render={(urlData) => (
        <DashboardCampaign {...urlData} {...props} show="share" />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/edit"
      render={(urlData) => (
        <CampaignScene {...urlData} {...props} show="legend" tab={false} />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/domain"
      render={(urlData) => (
        <Redirect
          to={`/campaign/${urlData.match.params.campaignId}/domain/choose`}
        />
      )}
    />

    <AuthRoute
      path="/campaign/:campaignId/domain/:step"
      render={(urlData) => (
        <CampaignScene
          {...urlData}
          {...props}
          show={localStorage.builderType || "legend"}
          popup="domain"
          tab={false}
        />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/tracking"
      render={(urlData) => (
        <CampaignScene
          {...urlData}
          {...props}
          show={localStorage.builderType || "legend"}
          popup="tracking"
          tab={false}
        />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/pagetype/:pageId"
      render={(urlData) => (
        <CampaignScene
          {...urlData}
          {...props}
          show={localStorage.builderType || "legend"}
          popup="pagetype"
          tab={false}
        />
      )}
    />

    <AuthRoute
      exact
      path="/campaign/:campaignId/publish"
      render={(urlData) => (
        <CampaignScene
          {...urlData}
          {...props}
          show={localStorage.builderType || "legend"}
          popup="publish"
          tab={false}
        />
      )}
    />
  </Fragment>
);

const PageEditorRoutes = (props) => (
  <Fragment>
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/builder"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="builder" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/builder/preview"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="preview" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/templates/"
      render={(urlData) => (
        <Redirect
          to={`/campaign/${urlData.match.params.campaignId}/${urlData.match.params.nodeId}/templates/all`}
        />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/templates/:type"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="templates" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/seo"
      render={(urlData) => <PageEditor {...urlData} {...props} show="seo" />}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/email"
      render={(urlData) => <PageEditor {...urlData} {...props} show="email" />}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/merchant"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="merchant" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/tracking"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="tracking" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/css"
      render={(urlData) => <PageEditor {...urlData} {...props} show="css" />}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/exit"
      render={(urlData) => <PageEditor {...urlData} {...props} show="exit" />}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/history"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="history" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/publish"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="publish" />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/email"
      render={(urlData) => (
        <Redirect
          to={`/campaign/${urlData.match.params.campaignId}/${urlData.match.params.nodeId}/email/provider`}
        />
      )}
    />
    <AuthRoute
      path="/campaign/:campaignId/:nodeId/email/:step"
      render={(urlData) => <PageEditor {...urlData} {...props} show="email" />}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/merchant"
      render={(urlData) => (
        <Redirect
          to={`/campaign/${urlData.match.params.campaignId}/${urlData.match.params.nodeId}/merchant/provider`}
        />
      )}
    />
    <AuthRoute
      exact
      path="/campaign/:campaignId/:nodeId/merchant/:step"
      render={(urlData) => (
        <PageEditor {...urlData} {...props} show="merchant" />
      )}
    />
  </Fragment>
);

const CatalogRoutes = (props) => (
  <Fragment>
    <AuthRoute
      exact
      path="/catalog/"
      render={(urlData) => <Redirect to="/catalog/funnels/" />}
    />
    <AuthRoute
      exact
      path="/catalog/:tabId"
      render={(urlData) => <Catalog {...urlData} {...props} />}
    />
    <AuthRoute
      exact
      path="/catalog/:tabId/:searchStr"
      render={(urlData) => <Catalog {...urlData} {...props} />}
    />
  </Fragment>
);

const IFrameRoutes = (props) => {
  return (
    <Fragment>
      <Route
        path="/r/:token"
        render={(rProps) => <IFrameRedirect {...props} {...rProps} />}
      />
    </Fragment>
  );
};

export default (props) => {
  return (
    <App
      {...settings}
      pageComponentIgnoreList={["paragraph", "textinput", "text"]}
    >
      {/* Authentication */}
      <LoginRoutes {...props} />

      {/* Dashboard */}
      <DashboardRoutes {...props} />

      {/* Settings */}
      <SettingsRoutes {...props} />

      {/* Campaigns */}
      <CampaignRoutes {...props} />

      {/* Page Editor */}
      <PageEditorRoutes {...props} />

      {/* Catalog */}
      <CatalogRoutes {...props} />

      {/* iFrames */}
      <IFrameRoutes {...props} />

      {/* Test */}
      <Route exact path="/test/" render={(urlData) => <Test />} />
      <Route exact path="/pure-editor/" render={(urlData) => <APureCanvas />} />

      {/* Any Routes From Plugins */}
      {triggerHook(
        HookTriggers.onListItems,
        { id: ListItemsHooks.ROUTES },
        window.location
      ).map((itm, key) => (
        <Fragment key={key}>{itm}</Fragment>
      ))}
    </App>
  );
};
