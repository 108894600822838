// import { app } from "../../../config";
import { decode, encode } from "@launchos/api/utils";
import { DialogBody } from "@launchos/components/ui";
import { AppConfig } from "@launchos/modules/app";
import _ from "lodash";
import React, { Component } from "react";
import providers from "../../settings/IntegrationManagement/integrationProviders";
import MerchantChooseAmount from "./ChooseAmount";
import MerchantChooseBilling from "./ChooseBilling";
import MerchantChooseCampaign from "./ChooseCampaign";
import MerchantChooseOffer from "./ChooseOffer";
import MerchantChooseProduct from "./ChooseProduct";
import MerchantChooseProvider from "./ChooseProvider";
import ShowStepper from "./ShowStepper";
import MerchantSuccess from "./Success";

export default class MerchantSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerCredentials: {},
      payload: {},
      campaign: {},
    };

    this.updatePaymentSettings = this.updatePaymentSettings.bind(this);
    this.updatePageSettings = this.updatePageSettings.bind(this);
  }

  static contextType = AppConfig;

  UNSAFE_componentWillMount() {
    const { app } = this.context;
    const { getPageQuery, match, history } = this.props;

    const pageSettings = getPageQuery.page.pageSettings;
    const storedMerchantPayload = _.get(
      pageSettings,
      "merchant.payload",
      false
    );
    const merchantPayload = storedMerchantPayload
      ? JSON.parse(decode(storedMerchantPayload))
      : false;

    console.log({ merchantPayload });

    if (match.params.step === "index") {
      let url = "./provider";

      if (_.has(merchantPayload, "campaignId") || !app.showProviderStep)
        url = "./campaign";
      if (_.has(merchantPayload, "amount")) url = "./amount";

      history.push(url);
    }
  }

  componentDidMount() {
    const { app } = this.context;
    const { getPageQuery, allIntegrations } = this.props;

    allIntegrations.refetch({ userId: localStorage.userId }).then((data) => {
      const pageSettings = getPageQuery.page.pageSettings || {};

      const providerType = _.get(
        this.state,
        "payload.type",
        app.defaultProvider
      );
      const payloadObj = data.data.integrations.filter((itm) => providerType);
      const payload = payloadObj.length
        ? { type: providerType, ...payloadObj[0].setting }
        : false;

      console.log({ providerType }, { data }, { payload }, { pageSettings });

      this.setState(
        {
          payload: pageSettings.hasOwnProperty("merchant")
            ? JSON.parse(decode(pageSettings.merchant.payload))
            : payload,
        },
        () => {
          console.log(this.state, { pageSettings });
        }
      );
    });
  }

  updatePaymentSettings(payload) {
    console.log({ payload });
    this.setState({ payload });
  }

  updatePageSettings() {
    const { app } = this.context;
    const { getPageQuery, match } = this.props;
    const { pageSettings } = getPageQuery.page;

    console.log("Got to this point");

    const variables = {
      pageId: match.params.nodeId,
      pageSettings: {
        ...pageSettings,
        merchant: {
          provider: _.get(this.state, "payload.type", app.defaultProvider),
          providerCredentials: encode(
            JSON.stringify(this.state.providerCredentials)
          ),
          payload: encode(JSON.stringify(this.state.payload)),
          amount: _.get(this.state.payload, "amount", 0),
          campaign: _.has(this.state.payload, "campaign")
            ? JSON.stringify(this.state.payload.campaign)
            : "{}",
          billingModel: _.has(this.state.payload, "billingModel")
            ? JSON.stringify(this.state.payload.billingModel)
            : "{}",
          productDetails: _.has(this.state.payload, "productDetails")
            ? JSON.stringify(this.state.payload.productDetails)
            : "{}",
        },
      },
    };

    console.log({ variables });

    this.props.updatePageSettings({ variables });
  }

  render() {
    const { app } = this.context;
    const myProps = {
      ...this.props,
      updateSettings: this.updatePaymentSettings,
      providerSettings: this.state,
    };
    const providerInfo = _.filter(
      providers,
      (o) =>
        o.id === _.get(this.state, "payload.type", app.defaultProvider) &&
        o.type === "merchant"
    );

    const step = this.props.step.toUpperCase();

    const initialTab = {
      id: "PROVIDER",
      label: "Payment Providers",
      show: this.props.showProvider ? true : false,
      nextHref: providerInfo.length
        ? _.get(providerInfo[0], "tabs[0].href", false)
        : false,
    };

    let tabs = [];

    if (providerInfo.length) {
      tabs = [initialTab, ...providerInfo[0].tabs];
    } else tabs = [initialTab];

    const activeStep = parseInt(
      _.findKey(
        _.filter(tabs, (t) => t.show),
        (o) => o.id === step
      ),
      10
    );

    console.log({ tabs }, { providers });

    const getComponent = (tab) => {
      switch (tab.id) {
        case "PROVIDER":
          return (
            <MerchantChooseProvider
              key={tab.id}
              nextHref={_.get(tab, "nextHref", "./campaign") || "./campaign"}
              {...myProps}
              filter="merchant"
              message="What payment provider should we use to send new orders that come from this page?"
              label="Search for a payment provider..."
            />
          );

        case "CAMPAIGN":
          return (
            <MerchantChooseCampaign
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./provider")}
              message="Which campaign should this page refer to?"
              nextHref={_.get(tab, "nextHref", "./offer")}
              {...myProps}
            />
          );

        case "AMOUNT":
          return (
            <MerchantChooseAmount
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./provider")}
              message="How much should we charge someone who makes a purchase on this page?"
              nextHref={_.get(tab, "nextHref", "./success")}
              {...myProps}
            />
          );

        case "OFFER":
          return (
            <MerchantChooseOffer
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./campaign")}
              nextHref={_.get(tab, "nextHref", "./billing")}
              {...myProps}
            />
          );

        case "BILLING":
          return (
            <MerchantChooseBilling
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./offer")}
              nextHref={_.get(tab, "nextHref", "./product")}
              {...myProps}
            />
          );

        case "PRODUCTS":
          return (
            <MerchantChooseProduct
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./billing")}
              nextHref={_.get(tab, "nextHref", "./success")}
              {...myProps}
            />
          );

        case "SUCCESS":
          return (
            <MerchantSuccess
              key={tab.id}
              prevHref={_.get(tab, "prevHref", "./products")}
              {...myProps}
              updatePageSettings={this.updatePageSettings}
              message="Your Payment Settings Have Been Saved"
              footer={`
                After publishing this page, any purchases made from
                this page, will be recorded as a new order in your
                ${providerInfo.length ? providerInfo[0].caption : ""}
                account.
              `}
            />
          );

        default:
          break;
      }
    };

    return (
      <div style={{ padding: 40 }}>
        {tabs.length > 1 ? (
          <div style={{ marginBottom: 40 }}>
            <ShowStepper {...this.props} tabs={tabs} activeStep={activeStep} />
          </div>
        ) : null}
        <DialogBody>
          {tabs.map((tab) => {
            return step === tab.id ? getComponent(tab) : null;
          })}
        </DialogBody>
      </div>
    );
  }
}

MerchantSettings.defaultProps = {
  step: "CAMPAIGN",
  showProvider: true,
};
