import { get, has, cloneDeep } from "lodash";
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import style from "./style";
import { MenuItemLabel } from '@launchos/components/ui'
import { listChildren } from "@launchos/modules/v2/Editor/actions";
import { renameObject } from "@launchos/modules/editor/Builder/actions";
import { triggerHook } from "@launchos/plugins/client";
import { HookTriggers, ListItemsHooks } from "@launchos/plugins/types";

// const AllMenuItems = ({
//   defaultItems = [],
//   dropDownItems = (items) => items,
// }) => {
//   const [useTheseDropDownItems, setUseTheseDropDownItems] = React.useState(
//     // iframeDropDownItems(pluginDropDownItems(dropDownItems(defaultItems)))
//   );

//   React.useEffect(() => {
//     // getDropDownItems();
//     setUseTheseDropDownItems(useTheseDropDownItems);
//   }, []);

//   // const itemsToRender = iframeDropDownItems(pluginDropDownItems(dropDownItems(defaultItems)));

//   return useTheseDropDownItems.map(
//     ({ id = false, icon = false, text }, key) =>
//       id === "divider" ? (
//         <div key={key} style={style.divider} />
//       ) : (
//         <MenuItem key={key} onClick={(e) => handleChange(id, e)}>
//           <MenuItemLabel icon={icon}>{text}</MenuItemLabel>
//         </MenuItem>
//       )
//   );
// };

export const DropDownMenu = (props) => {
  
  const {
    anchorEl,
    label,
    type,
    handleDropDownClose,
    onDropDownChange = () => true,
    rSettings,
    settings,
    removeItem,
    duplicateItem,
    addThisAfterThat,
    moveThisByThat,
    pageContent,
    updateComponentSettings,
    showProperties,
    getMobileState,
    changeState,
  } = props;

  // const currentMobileState = getMobileState();

  const siblings = pageContent.filter((itm) => itm.parent === settings.parent);
  const key = siblings.findIndex((itm) => itm.id === settings.id);

  const defaultMenuItems = [
    { id: "CUT", icon: "content_cut", text: "Cut" },
    { id: "COPY", icon: "content_copy", text: "Copy" },
    ...(window.localStorage.hasOwnProperty("clipboard")
      ? [{ id: "PASTE", icon: "content_paste", text: "Paste Below" }]
      : []),
    { id: "divider" },
    ...(_.has(siblings, key - 1)
      ? [{ id: "MOVE_UP", icon: "expand_less", text: "Move Up" }]
      : []),
    ...(_.has(siblings, key + 1)
      ? [{ id: "MOVE_DOWN", icon: "expand_more", text: "Move Down" }]
      : []),
    ...(_.has(siblings, key - 1) || _.has(siblings, key + 1)
      ? [{ id: "divider" }]
      : []),
    { id: "rename", icon: "input", text: "Rename" },
    { id: "duplicate", icon: "file_copy", text: "Duplicate" },
    { id: "delete", icon: "delete_forever", text: "Delete" },
    { id: "divider" },
    { id: "PROPERTIES", icon: "settings", text: `${label || type} Settings` },
  ];

  const items = typeof(props.dropDownItems) === 'function' ? props.dropDownItems(defaultMenuItems) : defaultMenuItems;
  const [useTheseDropDownItems, setUseTheseDropDownItems] = React.useState(items)

  const handleChange = (action, e) => {
    e.stopPropagation();
    if (onDropDownChange(action)) {
      switch (action) {
        case "duplicate":
          duplicateItem(settings.id);
          break;
        case "delete":
          removeItem(settings.id);
          break;
        case "save":
          break;
        case "rename":
          renameObject({ updateComponentSettings, settings });
          break;
        case "CUT": {
          // generate JSON
          const cnt = listChildren(pageContent, settings.id);
          const clipboard = JSON.stringify(cnt);

          // add to local storage
          window.localStorage.setItem("clipboard", clipboard);

          // now remove item
          removeItem(settings.id);
          break;
        }
        case "COPY": {
          // generate JSON
          const cnt = listChildren(pageContent, settings.id);
          const clipboard = JSON.stringify(cnt);

          // add to local storage
          if (typeof window === "object") {
            window.localStorage.setItem("clipboard", clipboard);
            // alert("The Element Has Been Copied");
          }

          break;
        }
        case "PASTE": {
          // grab from local storage
          const clipboard = JSON.parse(localStorage.getItem("clipboard"));
          // add to canvas below this section
          changeState(2540, "active", () => {
            // don't add sections below objects
            if (clipboard.type === "Section" && settings.type !== "Section") alert("You can only paste SECTION widgets below another SECTION widget");
            // it should probably remap the id's like it does in recursively duplicate (cloning ids is bad)
            // you could modify the duplicateItem function to return the new object (instead of updating the state)
            else addThisAfterThat(clipboard, settings.id);
          });

          break;
        }
        case "MOVE_UP": {
          if (_.has(siblings[key], 'id') || _.has(siblings[key - 1], 'id')) {
            moveThisByThat(_.get(siblings[key], 'id'), _.get(siblings[key - 1], 'id'));
          }

          break;
        }
        case "MOVE_DOWN": {
          if (_.has(siblings[key], 'id') || _.has(siblings[key + 1], 'id')) {
            moveThisByThat(_.get(siblings[key], 'id'), _.get(siblings[key + 1], 'id'));
          }

          break;
        }
        case "PROPERTIES": {
          showProperties();
          break;
        }
        case "reset":
          const { type } = getMobileState();
          const { rSettings } = props;
          if (has(rSettings, "properties.mobile")) {
            const properties = cloneDeep(rSettings.properties);
            delete properties.mobile[type];

            console.log(rSettings.properties, properties);
            updateComponentSettings(settings.id, {
              ...settings,
              properties,
            });
          }
          // console.log(type)
          break;
        default:
          break;
      }
    }

    triggerHook(
      HookTriggers.onMenuSelect,
      { id: ListItemsHooks.EDITOR_OBJECT_MENU, type },
      { id: action, ...props }
    );

    // if this is from an iframe, send the action back to the parent
    const isFromIframe = window.location !== window.parent.location;
    
    if (isFromIframe) {
      console.log({ action, useTheseDropDownItems });
      // post a message to the parent iframe so that it can handle the action
      const message = { 
        type: "editorObjectRequest", 
        request: "handleDropDownAction", 
        payload: { action, settings, items: useTheseDropDownItems, content: pageContent } 
      };

      window.parent.postMessage(message, "*");    
    }

    handleDropDownClose();
  };

  const pluginDropDownItems = (items) => {
    const newItems = triggerHook(
      HookTriggers.onListItems,
      { id: ListItemsHooks.EDITOR_OBJECT_MENU, type },
      items
    );
    if (newItems.length) return newItems;
    // console.log({ newItems })
    return items;
  };

  const iframeDropDownItems = async (items) => {

    const isFromIframe = window.location !== window.parent.location;

    if (isFromIframe) {
      // Create a promise that will resolve when the parent iframe sends a message back
      const messagePromise = new Promise((resolve) => {
        window.addEventListener("message", (e) => {
          if (e.data.type === "editorObjectResponse") {
            const { dropDownItems } = e.data;
            resolve(dropDownItems);
          }
        });
      });

      // Post a message to the parent iframe requesting the drop down items
      const message = { type: "editorObjectRequest", request: "getDropDownItems", payload: { settings, items } };
      window.parent.postMessage(message, "*");

      const dropDownItems = await messagePromise;
      return dropDownItems;
    }

    return items;
  };

  const getDropDownItems = async () => {
    const newItems = await iframeDropDownItems(pluginDropDownItems(items));
    setUseTheseDropDownItems(newItems);
  }

  React.useEffect(() => {
    getDropDownItems();
  }, [type]);

  return (
    <Menu
      id="editor-object-menu"
      anchorEl={anchorEl}
      open={get(anchorEl, "mouseY", null) !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        get(anchorEl, "mouseY", null) !== null &&
          get(anchorEl, "mouseX", null) !== null
          ? {
            top: get(anchorEl, "mouseY", null),
            left: get(anchorEl, "mouseX", null),
          }
          : undefined
      }
      onClose={handleDropDownClose}
    >
      {(label || type) && (
        <MenuItem
          disabled
          style={{
            backgroundColor: "#CCC",
            marginBottom: 0,
            marginTop: -8,
            borderBottom: "1px solid #BBB",
          }}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%",
              fontSize: "11pt",
              padding: 3,
              fontWeight: "bold",
              textShadow: "1px 1px rgba(255,255,255,0.5)",
            }}
          >
            - {label || type} Menu -
          </div>
        </MenuItem>
      )}


      {/* <AllMenuItems
        defaultItems={defaultMenuItems}
        dropDownItems={props.dropDownItems}
      /> */}

      {useTheseDropDownItems.map(
        ({ id = false, icon = false, text }, key) =>
          id === "divider" ? (
            <div key={key} style={style.divider} />
          ) : (
            <MenuItem key={key} onClick={(e) => handleChange(id, e)}>
              <MenuItemLabel icon={icon}>{text}</MenuItemLabel>
            </MenuItem>
          )
      )}










      {/* {location.hostname === "localhost" &&
      has(rSettings, `properties.mobile[${currentMobileState.type}]`) ? (
        <MenuItem
          onClick={(e) => {
            handleChange("reset", e);
          }}
        >
          <MenuItemLabel icon="format_color_reset">
            Clear Mobile Style
          </MenuItemLabel>
        </MenuItem>
      ) : (
        <div />
      )}
      <div key="divider2" style={style.divider} />
      {has(rSettings, "properties.mobile") ? (
        <MenuItem
          key="debug3"
          onClick={(e) => {
            const { rSettings, updateComponentSettings } = props;
            if (has(rSettings, "properties.mobile")) {
              const properties = cloneDeep(rSettings.properties);
              delete properties.mobile;
              updateComponentSettings(
                settings.id,
                {
                  ...settings,
                  properties,
                },
                true,
                false
              );
              handleDropDownClose();
            }
          }}
        >
          <MenuItemLabel icon="bug_report">Clear All Mobile</MenuItemLabel>
        </MenuItem>
      ) : null}

      <MenuItem
        key="debug5"
        onClick={(e) => {
          const { updateContent, pageContent } = props;
          updateContent(
            pageContent.map((itm) => {
              const content = cloneDeep(itm);
              if (has(content, "properties.mobile"))
                delete content.properties.mobile;
              return content;
            }),
            true,
            false,
            () => {
              window.location.href = window.location.href;
            }
          );
          handleDropDownClose();
        }}
      >
        <MenuItemLabel icon="bug_report">Clear Mobile Everywhere</MenuItemLabel>
      </MenuItem>

      <MenuItem
        key="anyHeights"
        onClick={(e) => {
          const { pageContent, changeState } = props;
          const content = pageContent.filter((itm) =>
            isNumber(get(itm, "properties.height"))
          );
          handleDropDownClose();
          alert(
            `${content.length} items were found (${content
              .map((itm) => itm.id)
              .join()})`
          );
          if (content.length) {
            changeState(head(content)["id"], "active");
            e.stopPropagation();
          }
        }}
      >
        <MenuItemLabel icon="bug_report">Any Heights?</MenuItemLabel>
      </MenuItem>

      <MenuItem
        key="debug4"
        onClick={(e) => {
          const { updateComponentSettings } = props;
          const properties = cloneDeep(rSettings.properties);
          delete properties.mobile;
          delete properties.fontFamily;
          delete properties.fontSize;
          delete properties.padding;
          delete properties.width;
          delete properties.height;
          delete properties.marginTop;
          delete properties.lineHeight;
          delete properties.textAlign;
          delete properties.color;

          const clearedStyle = {
            ...settings,
            properties,
          };
          // console.log({ clearedStyle })
          updateComponentSettings(settings.id, clearedStyle, true, false);
          handleDropDownClose();
        }}
      >
        <MenuItemLabel icon="bug_report">Clear {label} Styles</MenuItemLabel>
      </MenuItem> */}

      {location.hostname === "localhost" && (
        <div>
          <div key="divider1" style={style.divider} />
          <MenuItem
            key="debugMobile"
            onClick={(e) => {
              handleChange("reset", e);
            }}
          >
            <MenuItemLabel icon="format_color_reset">
              Clear Mobile Style
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            key="debug2"
            onClick={(e) => {
              e.stopPropagation();
              console.log("Settings", settings);
              console.log("Properties", rSettings.properties);
              handleDropDownClose();
            }}
          >
            <MenuItemLabel icon="bug_report">Debug</MenuItemLabel>
          </MenuItem>
          {/* <div key="divider1" style={style.divider} />
          <MenuItem
            key="debug2"
            onClick={(e) => {
              e.stopPropagation();
              // console.log("Settings", settings);
              // console.log("Properties", rSettings.properties);
              const framework_id = window.prompt("Enter Framework Id (e.g. 123)", get(settings, 'sc.framework_id'));
              if (framework_id) {
                updateComponentSettings(settings.id, {
                  ...settings,
                  sc: {
                    framework_id
                  }
                }, true, false);
              }

              handleDropDownClose();
            }}
          >
            <MenuItemLabel icon="bug_report">Set Smart Copy Framework</MenuItemLabel>
          </MenuItem> */}
        </div>
      )}
    </Menu>
  );
};
