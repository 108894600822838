import * as React from "react";
import WebFont from "webfontloader";
import { uniq } from "lodash";

import { debounce } from "lodash";
import { Editor } from "@tinymce/tinymce-react";

import { RichEditorProps } from "./types";
import { getInitValues, apiKey } from "./initValues";
import GoogleFontSelector from "./GoogleFontSelector";

import "./index.css";

/**
 * A component for providing rich, wysiwyg text composition.
 * (A wrapper of the Tiny MCE library)
 */
const RichEditor: React.FC<RichEditorProps> = (props) => {
  const {
    id,
    content = "",
    disabled = false,
    ref,
    onClick = () => null,
    onMouseEnter = () => null,
    onChange = () => null,
    onInstantChange = () => null,
  } = props;
  const [didChange, setDidChange] = React.useState<boolean>(false);
  const [fontsUsed, setFontsUsed] = React.useState<string[]>([]);

  const [
    isGoogleFontSelectorOpen,
    setIsGoogleFontSelectorOpen,
  ] = React.useState<boolean>(false);
  const [activeEditor, setActiveEditor] = React.useState<any>();
  const [doDebounce] = React.useState(() =>
    debounce(() => setDidChange(true), 1000)
  );
  const [html, setHTML] = React.useState<string>(content);

  React.useEffect(() => {
    // console.log("Content about to be changed", html, content);

    if (html !== content) { // don't trigger if content is the same
      onChange(html, fontsUsed);
    }
    setDidChange(false);
  }, [didChange]);

  React.useEffect(() => { // if the text content is updated externally (from the props) (not working!)
    setHTML(content)
    // onChange(content)
    setDidChange(true);
    // console.log("Content to be updated from external trigger", content);
    doDebounce()
  }, [content])

  React.useEffect(() => {
    setDidChange(true);
  }, []);

  const handleOnChange: (html: string) => void = (html) => {
    setHTML(html);
    onInstantChange(html, fontsUsed);
    doDebounce();
  };

  const openGoogleFontSelector: (editor: any) => void = (editor) => {
    setIsGoogleFontSelectorOpen(true);
    setActiveEditor(editor);
  };

  const handleGoogleFontSelection = (font, callbackFn = () => null) => {
    WebFont.load({
      google: {
        families: [font],
      },
      active: () => {
        callbackFn();
        activeEditor.execCommand("FontName", false, font);
        setIsGoogleFontSelectorOpen(false);
        setFontsUsed(uniq([...fontsUsed, font]));
      },
    });
  }

  const initialValues = getInitValues({
    ...props,
    openGoogleFontSelector,
  })

  const isSafari = typeof window === 'object' ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent) : false;

  return (
    <div
      id={id}
      data-testid="Editor-Components-RichEditor"
      data-placeholder="Click here to enter your content..."
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      onMouseEnter={() => {
        if (isSafari) {
          // Fixes Safari issue where TinyMCE doesn't allow editing inside of a <div draggable="true" /> container
          document
            .querySelectorAll("[draggable]")
            .forEach((el) => el.setAttribute("draggable", "false"));
        }
        onMouseEnter();
      }}
      onMouseLeave={() => {
        if (isSafari) {
          // Fixes Safari issue where TinyMCE doesn't allow editing inside of a <div draggable="true" /> container
          // ...but draggability ceases to work <- To Fix!
          document
            .querySelectorAll("[draggable]")
            .forEach((el) => el.setAttribute("draggable", "true"));
        }
      }}
      ref={ref}
    >
      <GoogleFontSelector
        open={isGoogleFontSelectorOpen}
        onClose={() => setIsGoogleFontSelectorOpen(false)}
        onSelect={handleGoogleFontSelection}
      />

      <Editor
        apiKey={apiKey}
        // initialValue={content}
        value={content}
        init={initialValues}
        onEditorChange={handleOnChange}
        disabled={Boolean(disabled)}
      />
    </div>
  );
};

export default RichEditor;
