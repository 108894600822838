import React from "react";
import { DropTarget } from "react-dnd";
import PageBuilder from "@launchos/modules/editor/Editor";
// import Builder from "@launchos/modules/editor/Builder";
// import settings from '@launchos/10mf/src/config'
import { useDimensions } from "@launchos/plugins/utilities";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

// import {
//   addHoverCursor,
//   addThisAfterThat,
//   changeState,
//   duplicateItem,
//   getActivePropertyWindows,
//   moveThisByThat,
//   removeItem,
//   setActiveObject,
//   updateComponentStyle,
//   updateComponentSettings,
//   updateContent,
// } from "@launchos/modules/v2/Editor/actions"
// import { EditorActions, CURSOR_ID } from "@launchos/modules/v2/Editor/types";

import Canvas from "../Canvas";
import { shallowEqual } from '../WebComponent/WebComponent'
import _ from "lodash";

class CPureCanvas extends PageBuilder {
  constructor(props) {
    super(props)
    // this.setState = console.log
  }

  // update the state when the prop content changes
  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.content !== this.props.content) {
    if (!shallowEqual(prevProps.content, this.props.content)) {
      this.setState({ content: this.props.content })
    }

    else if (!shallowEqual(prevState.content, this.state.content)) {
      // console.log("Detected on page content change - should {not} save now", this.state.content)
      this.props.updatePageContent({ variables: { content: this.state.content, shouldSave: false } }).then(null)
    }
  }

  render() {

    const { content } = this.state;
    const { connectDropTarget, children } = this.props;

    const editorProps = {
      hidden: false,
      content: "edit",
      pageContent: content,
      removeItem: this.removeItem,
      duplicateItem: this.duplicateItem,
      moveThisByThat: this.moveThisByThat,
      addHoverCursor: this.addHoverCursor,
      addThisAfterThat: this.addThisAfterThat,
      changeState: this.changeState,
      state: this.state.state,
      updateContent: this.updateContent,
      updateComponentStyle: this.updateComponentStyle,
      updateComponentSettings: this.updateComponentSettings,
      listAncestors: this.listAncestors,
      listComponents: this.listComponents,
      setActiveObject: this.setActiveObject,
      doUndoRedo: this.doUndoRedo,
      undoStackSize: this.state.undoStack.length - 1,
      undoPosition: this.state.undoPosition,
      setCanUndo: this.setCanUndo,
      setMobileState: this.setMobileState,
      getMobileState: this.getMobileState,
      currentMobileState: this.state.mobileState,
      setActivePropertyWindows: this.setActivePropertyWindows,
      getActivePropertyWindows: this.getActivePropertyWindows,
      setCanDrag: this.setCanDrag,
      getCanDrag: this.getCanDrag,
    };

    return <div>
      {children(editorProps)}
      {connectDropTarget(
        <div>
          <Canvas
            {...this.props}
            {...editorProps}
          />
        </div>
      )}
    </div>
  }
}

const PureCanvas = props => {
  const { content, connectDropTarget, onChange = () => null, onSave = () => null, children, render, onItemSelect } = props;
  const [ref, hoverBoundingRect] = useDimensions();

  return (
    <DndProvider backend={Backend}>
      <div ref={ref}>
        <CPureCanvas
          history={{
            location: {
              pathname: window.location.pathname,
            },
          }}
          match={{
            path: "",
            params: {
              campaignId: "cjyuopfrx00ga0792rxk0yha9",
              nodeId: "cjyuopfxq00gh07924rep6q96",
            },
          }}
          getPageQuery={{
            page: {
              type: false,
              name: "",
              id: "",
              content,
            },
            refetch: () => {
              return {
                data: { page: { content } },
              };
            },
          }}
          updatePageContent={({ variables }) => {
            return {
              then: (response) => {
                // console.log('updating content', variables)
                // onChange({ ...variables, shouldSave: _.get(variables, 'shouldSave', true) })
                const shouldSave = _.get(variables, 'shouldSave', true);

                if (!shouldSave) onChange(variables)
                if (shouldSave) onSave(variables)

              },
            };
          }}
          content={content}
          connectDropTarget={connectDropTarget}
          ecCoordinates={hoverBoundingRect}
          children={children}
          render={render}
          onItemSelect={onItemSelect}
        />
      </div>
    </DndProvider>
  )
}

export default DropTarget(
  "CANVAS",
  {
    hover: (props, monitor) => {
      const { onResize } = monitor.getItem();
      onResize(props, monitor);
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(PureCanvas);

// const PureCanvas = (props) => {
//   const { connectDropTarget, content, onChange } = props;
//   const [ref, hoverBoundingRect] = useDimensions();

//   const mobileStateData = {
//     desktop: {
//       type: "desktop",
//       dimensions: {
//         maxWidth: 1000,
//         minHeight: "70vh",
//         margin: "80px auto",
//         overflow: "hidden",
//       },
//     },
//     fullscreen: {
//       type: "fullscreen",
//       dimensions: {
//         width: "100%",
//         height: "100vh",
//         marginTop: 57,
//       },
//     },
//   };

//   const defaultMobileState = mobileStateData.fullscreen;

//   const l = console.log

//   return connectDropTarget(
//     <div ref={ref}>
//       <Canvas
//         history={{ location: { pathname: window.location.pathname } }}
//         match={{
//           path: "",
//           params: {
//             campaignId: "cjyuopfrx00ga0792rxk0yha9",
//             nodeId: "cjyuopfxq00gh07924rep6q96",
//           },
//         }}
//         getPageQuery={{
//           page: {
//             type: false,
//             name: "",
//             id: "",
//             content,
//           },
//           refetch: () => {
//             return {
//               data: { page: { content } },
//             };
//           },
//         }}
//         updatePageContent={({ variables }) => {
//           return {
//             then: (response) => response,
//           };
//         }}
//         show="builder"
//         defaultMobileState={defaultMobileState}
//         // -------------------------------------------------- //
//         addHoverCursor={addHoverCursor} // TODO
//         addThisAfterThat={addThisAfterThat} // TODO
//         allCatalogDesignItems={() => l('allCatalogDesignItems')}
//         allIntegrations={{}}
//         changeState={
//           (id, state, callbackFn = () => null) => {
//             const newContent = changeState(content, id, state, callbackFn);
//             if (newContent) onChange(EditorActions.CHANGE_STATE, newContent, false)
//           }
//         } // TODO
//         content="edit"
//         createIntegration={() => l('createIntegration')}
//         createMediaItem={() => l('createMediaItem')}
//         doUndoRedo={() => l('doUndoRedo')}
//         duplicateItem={duplicateItem} // TODO
//         ecCoordinates={hoverBoundingRect}
//         getActivePropertyWindows={getActivePropertyWindows} // TODO
//         getCampaignQuery={() => l('getCampaignQuery')}
//         getCanDrag={() => l('getCanDrag')}
//         getMobileState={() => defaultMobileState}
//         getProviderCampaigns={() => l('getProviderCampaigns')}
//         getProviderOffers={() => l('getProviderOffers')}
//         getProviderProducts={() => l('getProviderProducts')}
//         getUserQuery={() => l('getUserQuery')}
//         hidden={false}
//         listAncestors={() => l('listAncestors')}
//         listComponents={() => l('listComponents')}
//         location={{ pathname: window.location.pathname, }}
//         moveThisByThat={moveThisByThat} // TODO
//         pageContent={content}
//         publishPage={() => l('publishPage')}
//         removeItem={removeItem} // TODO
//         setActiveAddNewTab={() => l('setActiveAddNewTab')}
//         setActiveObject={setActiveObject} // TODO
//         setActivePropertyWindows={() => l('setActivePropertyWindows')}
//         setCanDrag={() => l('setCanDrag')}
//         setCanUndo={() => l('setCanUndo')}
//         setMobileState={() => l('setMobileState')}
//         settings={settings}
//         state={{ "active": false, "hover": false }}
//         toggleAddNewDrawer={() => l('toggleAddNewDrawer')}
//         undoPosition={1}
//         undoStackSize={1}
//         updateCampaignObjectMutation={() => l('updateCampaignObjectMutation')}
//         updateCampaignQuery={() => l('updateCampaignQuery')}
//         updateComponentStyle={updateComponentStyle} // TODO
//         updateComponentSettings={updateComponentSettings} // TODO
//         updateContent={updateContent} // TODO
//         updateIntegration={() => l('updateIntegration')}
//         updatePageName={() => l('updatePageName')}
//         updatePageSettings={() => l('updatePageSettings')}
//         updatePageType={() => l('updatePageType')}
//         uploadURLToS3={() => l('uploadURLToS3')}
//       />
//     </div>
//   );
// };

// export default DropTarget(
//   "CANVAS",
//   {
//     hover: (props, monitor) => {
//       const { onResize } = monitor.getItem();
//       onResize(props, monitor);
//     },
//   },
//   (connect) => ({
//     connectDropTarget: connect.dropTarget(),
//   })
// )(CPureCanvas);
