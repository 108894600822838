import { RichEditorPosition, RichEditorProps } from "./types";
import gql from "graphql-tag";
import { client } from "@launchos/api/gql/client";

// export const apiKey = [
//   "7z88tu3b62n6fx2d9sep5tqidhd8gzuh5t8ipu0wc1qzgklu",
//   "wnb2ywdwi4n0nxtz1w0dn98jhrymw9s95mh1287cnhcc4vb3",
//   // "uuv3q8un8zkygn069w23r05eufi9dghc6imhriibwja6iu1g",
// ][Math.floor(Math.random() * 3)];
export const apiKey = [
         "wnb2ywdwi4n0nxtz1w0dn98jhrymw9s95mh1287cnhcc4vb3",
         "7z88tu3b62n6fx2d9sep5tqidhd8gzuh5t8ipu0wc1qzgklu",
       ][Math.floor(Math.random() * 2)];

const defaultLineHeightList = [
  "0.3rem",
  "0.5rem",
  "0.7rem",
  "0.9rem",
  "1.0rem",
  "1.1rem",
  "1.2rem",
  "1.3rem",
  "1.4rem",
  "1.6rem",
  "1.8rem",
  "2.0rem",
  "2.1rem",
  "2.2rem",
  "2.3rem",
  "2.4rem",
  "2.5rem",
  "3.0rem",
  "3.1rem",
  "3.2rem",
  "3.3rem",
  "3.4rem",
  "3.5rem",
  "4.0rem",
  "4.5rem",
  "5.0rem",
  "5.5rem",
];

const defaultFontSizeList = [
  "0.8rem",
  "0.9rem",
  "1.0rem",
  "1.1rem",
  "1.2rem",
  "1.4rem",
  "1.6rem",
  "1.8rem",
  "2.0rem",
  "2.2rem",
  "2.4rem",
  "2.8rem",
  "3.2rem",
  "3.6rem",
  "4.2rem",
  "4.8rem",
  "5.6rem",
  "6.2rem",
  "7.2rem",
  "10rem",
];

const defaultFontFormatList = [
  { name: "Andale Mono", font: "andale mono,times" },
  { name: "Arial", font: "arial,helvetica,sans-serif" },
  { name: "Arial Black", font: "arial black,avant garde" },
  { name: "Book Antiqua", font: "book antiqua,palatino" },
  { name: "Comic Sans MS", font: "comic sans ms,sans-serif" },
  { name: "Courier New", font: "courier new,courier" },
  { name: "Georgia", font: "georgia,palatino" },
  { name: "Helvetica", font: "helvetica" },
  { name: "Impact", font: "impact,chicago" },
  { name: "Symbol", font: "symbol" },
  { name: "Tahoma", font: "tahoma,arial,helvetica,sans-serif" },
  { name: "Terminal", font: "terminal,monaco" },
  { name: "Times New Roman", font: "times new roman,times" },
  { name: "Trebuchet MS", font: "trebuchet ms,geneva" },
  { name: "Verdana", font: "verdana,geneva" },
  { name: "Webdings", font: "webdings" },
  { name: "Wingdings", font: "wingdings,zapf dingbats" },
];

export const getInitValues = ({
  buttonList = [],
  menuList = [],
  lineHeightList = defaultLineHeightList,
  fontSizeList = defaultFontSizeList,
  fontFormatList = defaultFontFormatList,
  googleFontList = [],
  disableInlineControls = false,
  position = RichEditorPosition.INLINE,
  placeholder = "Click here to enter your content...",
  style = "",
  onFocus = (e) => null,
  onBlur = (e) => null,
  onKeyCommand = (e) => null,
  onSelectText = (e) => null,
  openGoogleFontSelector = () => null,
}) => {
  const inline: boolean = position === RichEditorPosition.INLINE;
  const plugins: string[] = [
    // "autolink",
    "link",
    "lists",
    // "powerpaste",
    "quickbars",
    "fullscreen",
  ];

  let buttons: string = "";
  try {
    buttons = disableInlineControls
      ? ""
      : buttonList.map((itm) => itm.join(" ")).join(" | ");
  } catch (err) {}

  const toolbar: boolean | string =
    position === RichEditorPosition.INLINE ? false : buttons;
  const contextmenu: string = menuList.map((itm) => itm.join(" ")).join(" | ");
  const lineheight_formats: string = lineHeightList.join(" ");
  const fontsize_formats: string = fontSizeList.join(" ");
  const font_formats: string = fontFormatList
    .map((itm) => `${itm.name}=${itm.font}`)
    .join("; ");
  const quickbars_selection_toolbar: boolean | string =
    position === RichEditorPosition.INLINE ? buttons : false;

  const setup = (editor) => {
    let shouldIBlur = true;
    const getGoogleFontMenuList = (editor) => [
      {
        type: "menuitem",
        text: "Open Google Font Selector...",
        onAction: () => {
          shouldIBlur = false;
          openGoogleFontSelector(editor);
        },
      },
      {
        type: "menuitem",
        text: "──────────────",
      },
      ...googleFontList.map(({ name, font }) => ({
        type: "menuitem",
        text: name,
        onAction: () => {
          editor.execCommand("FontName", false, font);
        },
      })),
    ];

    const writeContent = (text, i, type) => {
      i = i | 0;
      setTimeout(() => {
        try {
          if (type === "replace" && i === 0) {
            editor.execCommand("mceReplaceContent", false, text[i]);
          } else editor.insertContent(text[i]);

          writeContent(text, (i += 1), type);
        } catch (e) {}
      }, 3);
    };

    const doGPT = async (prompt) => {
      return await client.query({
        query: gql`
          query getGPTCompletion($prompt: String!, $type: String) {
            getGPTCompletion(prompt: $prompt, type: $type) {
              statusCode
              data
            }
          }
        `,
        variables: {
          prompt,
          type: "Headline",
        },
      });
    };

    editor.on("focus", onFocus);

    editor.on("blur", (event) => {
      event.stopPropagation();
      if (shouldIBlur) editor.setContent(editor.getContent()); // trick for turning off current highlighted selection // crashes the google font menu
      onBlur(event);
    });

    editor.on("ExecCommand", (e) => {
      // console.log(e.command);
      if (e.command === "FontName") {
        shouldIBlur = true;
      }

      // if (e.command === "mceInsertContent") {
      //   console.log(e);
      // }
    });

    editor.on("keyup", (event) => {
      if (event.key === "Delete") event.stopPropagation();
      onKeyCommand(event);
    });

    editor.on("AfterSetSelectionRange", onSelectText);

    editor.ui.registry.addNestedMenuItem("googlefonts", {
      text: "Google Fonts",
      getSubmenuItems: () => getGoogleFontMenuList(editor),
    });

    editor.ui.registry.addMenuItem("smartcopy", {
      text: "Suggest Variation",
      onAction: async () => {
        // const prompt = editor.getContent({ format: "text" });
        const prompt = editor.selection.getContent({ format: "text" });
        console.log({ prompt });

        const { data } = await doGPT(`Rewrite the following: ${prompt}`);
        const newText = data.getGPTCompletion.data[0].text;
        // writeContent("<p>" + newText.trim() + "</p>", 0);
        // editor.insertContent(`<p>${newText.trim()}</p>`);
        // editor.insertContent("<span>" + newText.trim() + "</span>"); // find ways to preserve font if select all
        writeContent(newText, 0, "replace");

        // writeContent(`<span>Testing 123 - ${prompt}</span>`, 0);
      },
    });

    editor.shortcuts.add("meta+13", "GPT Completion", async () => {
      const prompt = editor.getContent({ format: "text" });

      const { data } = await doGPT(prompt);
      const newText = data.getGPTCompletion.data[0].text;
      writeContent(newText, 0);
    });
  };

  return {
    menubar: false,
    inline,
    placeholder,
    plugins,
    quickbars_insert_toolbar: false,
    toolbar,
    toolbar_location: position,
    contextmenu,
    relative_urls: false,
    convert_urls: true,
    // document_base_url: "http://",
    // powerpaste_word_import: "clean",
    // powerpaste_html_import: "clean",
    icons: "small",
    quickbars_selection_toolbar,
    setup,
    statusbar: false,
    content_style: style,
    lineheight_formats,
    fontsize_formats,
    font_formats,
  };
};
